import {Injectable} from '@angular/core';
import { Observable, Subscription, throwError, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {NCAResponse, Subject} from "@shared/models";
import {LocalizationService} from "@core/services/globaltranslate.service";
import {NgxSpinnerService} from "ngx-spinner";

@Injectable()
export class NcalayerService {
  private webSocket: WebSocket;
  private nextAction: Function;
  public cardStoreName = 'AKKZIDCardStore';
  private defaults = {
    storageName: 'PKCS12',
    keyType: 'SIGNATURE',
    additionDataFlag: false,
    currentDirectory: '',
  };

  constructor(private translate: LocalizationService, private spinner: NgxSpinnerService) {}

  private initialize(): void {
    this.webSocket = new WebSocket('wss://localhost:13579/');

    this.webSocket.onopen = (event) => {
      if (this.nextAction) { this.nextAction(); }
    };

    this.webSocket.onclose = event => {
      this.onWebSocketClose(event);
    };

    this.webSocket.onmessage = event => {
      this.onWebSocketMessage(event);
    };
    this.webSocket.onerror = event=>{
      console.error(event);
    };
  }

  private onWebSocketClose(event) {
    console.log('Code: ' + event.code + ' Reason: ' + event.reason);
    if (event.wasClean) {
      console.log('connection has been closed');
    } else {
      console.log('Connection error');
      const confirmMessage = this.translate.get('nca-layer-connection-problem');

      if (confirm(confirmMessage) === true) {
        this.initialize();
      }
    }
  }

  private onWebSocketMessage(event) {
    const result: NCAResponse = JSON.parse(event.data);

    if (result == null) { return; }

    if (this.nextAction) { this.nextAction(result); }
  }

  clearNextAction() { this.nextAction = null; }

  getData(
    ncaModule: string,
    method: string,
    args?: Array<string|boolean|number|object>,
  ): Observable<NCAResponse> {
    const that = this;
    const params = { method, args };

    if (ncaModule) {

      Object.assign(params, {
        module: ncaModule,
      });
    }

    const paramString = JSON.stringify(params);

    return new Observable((observer) => {
      let subscription: Subscription;
      this.spinner.show();
      if (that.webSocket.readyState !== 1) {
        setTimeout(() => {
          subscription = that.getData(ncaModule, method, args)
            .subscribe({
              next:response => {
                observer.next(response);
              },
              error:error => {
                this.spinner.hide();
                observer.error(error);
              }
            });
        }, 1000);
      } else {
        that.nextAction = (response: NCAResponse) => {
          this.spinner.hide();
          if ( response.code === '500') {
            observer.error(response);
          }

          if (response.code === '200') {
            observer.next(response);
            that.clearNextAction();
          }
        };

        that.webSocket.send(paramString);
      }

      return { unsubscribe() {
        if (subscription) { subscription.unsubscribe(); }

        that.clearNextAction();
      } };
    });
  }

  isValidityDate(response: NCAResponse): boolean {
    if (!response.responseObject) {
       return false;
    }

    const {
      certNotBefore: start,
      certNotAfter: end,
    } = response.responseObject;

    const curDate = new Date().valueOf();

    if (curDate > +start && curDate < +end) {
      return true;
    }

    response.message = this.translate.get('eds-dates-not-valid');
    return false;
  }

  getActiveTokens(): Observable<NCAResponse> {
    const ncaModule = 'kz.gov.pki.knca.commonUtils';
    const method = 'getActiveTokens';

    return this.getData(ncaModule, method);
  }

  getKeyInfo(storageName: string = this.defaults.storageName): Observable<any> {
    if (this.webSocket == null) {
      this.initialize();
    }
    const ncaModule = 'kz.gov.pki.knca.commonUtils';
    const method = 'getKeyInfo';
    const args = [storageName];
    return this.getData(ncaModule, method, args).pipe(
      mergeMap(response => {
        const result = response.responseObject;
        result.subjectDn = this.normalizeSubjectInfo(result.subjectDn);
        if (!this.isValidityDate(response)) {
          return throwError(response);
        }

        return of(result);
      })
    );
  }

  private normalizeSubjectInfo(sdn: string): Subject {
    const sdnRawAttr = sdn.split(',');
    const sdnAttr: any = {};
    const attrMap = {
      SERIALNUMBER: 'iin',
      CN: 'cn',
      OU: 'bin',
      E: 'email',
      G: 'middleName',
      O: 'orgName',
    };

    sdnRawAttr.forEach(it => {
      const [key, val] = it.split('=');
      if (key in attrMap) {
        sdnAttr[attrMap[key]] = val;
      }
    });

    sdnAttr.iin = sdnAttr.iin.substr(3);
    sdnAttr.userName = [
      sdnAttr.cn,
      sdnAttr.middleName
    ].join(' ');

    if (sdnAttr.bin) {
      if (sdnAttr.bin.length > 3) {
        sdnAttr.bin = sdnAttr.bin.substr(3);
      }

      sdnAttr.orgName = sdnAttr.orgName.replace(/\\\"/g, '"');
    }

    return sdnAttr as Subject;
  }

  signXml(
    xmlToSign: string,
    storageName: string = this.defaults.storageName,
    keyType: string = this.defaults.keyType,
  ): Observable<NCAResponse> {
    if (this.webSocket == null) {
      this.initialize();
    }
    const ncaModule = 'kz.gov.pki.knca.commonUtils';
    const method = 'signXml';
    const args = [
      storageName,
      keyType,
      xmlToSign,
      '', ''
    ];

    return this.getData(ncaModule, method, args).pipe(
      mergeMap(response=>{
        return of(response);
      })
    );
  }

  signXmlArray(
    xmlToSign: Array<string>,
    storageName: string = this.defaults.storageName,
    keyType: string = this.defaults.keyType,
  ): Observable<NCAResponse> {
    if (this.webSocket == null) {
      this.initialize();
    }
    const ncaModule = 'kz.gov.pki.knca.commonUtils';
    const method = 'signXmls';
    const args = [
      storageName,
      keyType,
      xmlToSign,
      '',
      ''
    ];

    return this.getData(ncaModule, method, args).pipe(
      mergeMap(response=>{
        return of(response);
      })
    );
  }



  createCMSSignatureFromFile(
    filePath: string,
    flag?: boolean,
    storageName?: string,
    keyType?: string
  ): Observable<NCAResponse> {
    const ncaModule = 'kz.gov.pki.knca.commonUtils';
    const method = 'createCMSSignatureFromFile';
    const args = [
      storageName,
      keyType,
      filePath,
      flag
    ];

    return this.getData(ncaModule, method, args);
  }

  createCMSSignatureFromBase64(
    base64ToSign: string,
    flag: boolean = this.defaults.additionDataFlag,
    storageName: string = this.defaults.storageName,
    keyType: string = this.defaults.keyType
  ): Observable<NCAResponse> {
    const ncaModule = 'kz.gov.pki.knca.commonUtils';
    const method = 'createCMSSignatureFromBase64';
    const args = [
      storageName,
      keyType,
      base64ToSign,
      flag
    ];

    return this.getData(ncaModule, method, args);
  }

  showFileChooser(
    fileExtension: string = 'ALL',
    currentDirectory: string = '',
  ): Observable<NCAResponse> {
    const ncaModule = 'kz.gov.pki.knca.commonUtils';
    const method = 'showFileChooser';
    const args = [fileExtension, currentDirectory];

    return this.getData(ncaModule, method, args);
  }

  changeLocale(language: string): void {
    const ncaModule = 'kz.gov.pki.knca.commonUtils';
    const method = 'changeLocale';
    const args = [language];

    this.getData(ncaModule, method, args)
      .subscribe(() => {});
  }
}
